<template>
  <div>
    <b-card>
      <b-col>
        <b-row align-h="center">
          <b-form-group
            v-if="dadosForm"
            label-cols-lg="12"
            label-size="lg"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-row>
              <label><strong>CLIENTE:</strong></label>
              <b-col sm="8">
                <b-form-input
                  v-model="dados.cliente.nome"
                  size="sm"
                  disabled
                  class="mb-2 text-center"
                />
              </b-col>
            </b-row>
            <b-row>
              <label><strong>SINISTRO:</strong></label>
              <b-col sm="8">
                <b-form-input
                  v-model="dados.sinistro"
                  size="sm"
                  disabled
                  class="mb-2 text-center"
                />
              </b-col>
            </b-row>
          </b-form-group>
        </b-row>
      </b-col>
      <b-col>
        <b-row align-h="between">
          <b-card
            title="Assinatura Cliente"
            class="signature-card"
          >
            <VueSignaturePad
              ref="signaturePadCliente"
              width="500px"
              height="500px"
              name="cliente"
            />
          </b-card>
          <b-card
            title="Assinatura Técnico"
            class="signature-card"
          >
            <VueSignaturePad
              ref="signaturePadTecnico"
              width="500px"
              height="500px"
              name="tecnico"
            />
          </b-card>
        </b-row>
      </b-col>
      <b-row
        align-h="center"
        class="mt-2"
      >
        <b-button
          class="mr-2"
          @click="clear"
        >
          Limpar
        </b-button>
        <b-button
          variant="primary"
          @click="salvarAssinaturas"
        >
          Assinar
        </b-button>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { BCard, BButton, BRow, BCol, BFormInput } from 'bootstrap-vue'
// eslint-disable-next-line
import { VueSignaturePad } from 'vue-signature-pad'

import axios from 'axios'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    VueSignaturePad,
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_ROOT_API,
      dados: {},
      id: '',
      dadosForm: false,
    }
  },
  created() {
    const url = new URL(window.location.href)
    const hash = url.pathname.split('/').pop()
    this.id = hash
    this.getDados(hash)
  },
  methods: {
    getDados(id) {
      axios.get(`${this.baseURL}/api/orcamento/getDados/${id}`)
        .then(res => {
          this.dadosForm = true
          this.dados = res.data.dados
        })
    },
    clear() {
      this.$refs.signaturePadCliente.clearSignature()
      this.$refs.signaturePadTecnico.clearSignature()
    },
    salvarAssinaturas() {
      const assinaturas = {
        cliente: null,
        tecnico: null,
      }

      if (this.$refs.signaturePadCliente.isEmpty) {
        assinaturas.cliente = this.$refs.signaturePadCliente.saveSignature()
      }

      if (this.$refs.signaturePadTecnico.isEmpty) {
        assinaturas.tecnico = this.$refs.signaturePadTecnico.saveSignature()
      }
      if (assinaturas.tecnico.isEmpty && assinaturas.cliente.isEmpty) {
        this.$swal({
          title: 'ERRO!',
          icon: 'error',
          text: 'NENHUMA ASSINATURA FEITA',
          customClass: {
            confimButton: 'btn btn-danger',
          },
        })
      } else {
        axios.post(`${this.baseURL}/api/assinar/${this.id}`, assinaturas)
          .then(() => {
            this.$swal({
              title: 'Sucesso!',
              text: 'Assinatura enviada',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            window.location.reload()
          })
          .catch(() => {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: 'Ocorreu erro ao enviar as assinaturas',
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          })
      }
    },
  },
}
</script>

<style>
.signature-card {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}
</style>
